import * as React from 'react';
import InputText from '../../../../MyInput';
import SelectBox from '../SelectBox';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch
} from 'react-hook-form';

const withRefAsProps = Component =>
  React.forwardRef((props, ref) => <Component {...props} forwardRef={ref} />);

const withRefFromProps =
  Component =>
  ({ forwardRef, ...props }) =>
    <Component {...props} ref={forwardRef} />;

export const withFormContext = formConfig => Component => props => {
  const methods = useForm(formConfig);
  return (
    <FormProvider {...methods}>
      <Component {...props} />
    </FormProvider>
  );
};

export const withErrorMessage = Component => props => {
  const { errorMessage, name } = props;
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  let finalErrorMessage = '';

  if (typeof errorMessage === 'string') {
    finalErrorMessage = errorMessage;
  } else if (errors && errors[name]) {
    // find first error and display
    const firstError = errors[name].type;
    finalErrorMessage = errorMessage[firstError];
  }

  return (
    <Component error={errors[name]} message={finalErrorMessage} {...props} />
  );
};

const withResetOnDestroy = Component => props => {
  const { setValue, control } = useFormContext();
  const { name, resetOnDestroy } = props;

  React.useEffect(() => {
    return () => {
      if (resetOnDestroy) {
        setValue(name, null);
      }
    };
  }, [name, resetOnDestroy, setValue]);

  return <Component {...props} />;
};

const withSelectedValue = Component => props => {
  const { setValue, control } = useFormContext();
  const { name, options, resetOnNotFound } = props;
  const value = useWatch({
    control,
    name
  });

  if (value && options && resetOnNotFound) {
    const hasValue = options.some(item => item.key === value.key);
    if (!hasValue) {
      setValue(name, null);
    }
  }

  return <Component value={value} {...props} />;
};

export const FormInputText = withRefAsProps(
  withErrorMessage(withRefFromProps(InputText))
);
export const FormSelectBox = withRefAsProps(
  withErrorMessage(
    withResetOnDestroy(withSelectedValue(withRefFromProps(SelectBox)))
  )
);
